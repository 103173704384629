
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import OrderForm from './OrderForm';
// import { Link } from 'react-router-dom';
// import Button from './Button';
// // import './ProductsPage.css';

// const ProductsPage = () => {
//     const [products, setProducts] = useState([]);
//     const [selectedProduct, setSelectedProduct] = useState(null); // Храним выбранный продукт
//     const [showOrderForm, setShowOrderForm] = useState(false); // Флаг отображения формы

//     useEffect(() => {
//         // Получаем данные с API
//         fetch('/api/products/')
//             .then((response) => {
//                 console.log('Ответ от сервера:', response); // Выводим ответ в консоль
//                 if (!response.ok) {
//                     throw new Error('Ошибка сети');
//                 }
//                 // Временно используем .text() вместо .json() для отладки
//                 return response.text();  // Измените на .text() для проверки
//             })
//             .then((data) => {
//                 console.log('Ответ от API как текст:', data); // Выводим текстовый ответ
//                 try {
//                     const jsonData = JSON.parse(data); // Попытка вручную распарсить JSON
//                     setProducts(jsonData);  // Устанавливаем данные в состояние
//                     console.log('Распарсенные данные:', jsonData);
//                 } catch (error) {
//                     console.error('Ошибка парсинга JSON:', error);
//                 }
//             })
//             .catch((error) => console.error('Ошибка при загрузке данных:', error)); // Ловим и выводим ошибку
//     }, []);

//     const handleOrderClick = (product) => {
//         setSelectedProduct(product);  // Устанавливаем выбранный продукт
//         setShowOrderForm(true);  // Показываем форму заказа
//     };

//     return (
//         <div>
//             <h1>Список продуктов</h1>
//             <Link to="/">
//                 <Button>Вернуться на главную</Button>
//             </Link>
//             <ul>
//                 {products.map(product => (
//                     <li key={product.id}>
//                         <h2>{product.name}</h2>
//                         <p>{product.description}</p>
//                         <p>Цена за день аренды: {product.rental_price_per_day} ₽</p>
//                         <button onClick={() => handleOrderClick(product)}>Заказать</button>
//                         {product.is_available ? (
//                             <p style={{ color: 'green' }}>Доступно для аренды</p>
//                         ) : (
//                             <p style={{ color: 'red' }}>Недоступно (в аренде)</p>
//                         )}
//                     </li>
//                 ))}
//             </ul>
//             {/* Форма заказа */}
//             {showOrderForm && (
//                 <OrderForm product={selectedProduct} />
//             )}
//         </div>
//     );
// };

// // Экспорт компонента по умолчанию
// export default ProductsPage;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OrderForm from './OrderForm';
import { Link } from 'react-router-dom';
import Button from './Button';

const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showOrderForm, setShowOrderForm] = useState(false);

    useEffect(() => {
        // Используем полный URL API
        axios.get('https://cableforce.pro/api/products/')
            .then((response) => {
                console.log('Полученные данные:', response.data);

                const formattedData = response.data.map((product) => ({
                    id: product.ID,
                    name: product.Name,
                    description: product.Description,
                    rental_price_per_day: product.RentalPricePerDay,
                    is_available: product.Stock > 0, // Определяем доступность по наличию на складе
                }));
                setProducts(response.data);
            })
            .catch((error) => console.error('Ошибка при загрузке данных:', error));
    }, []);

    const handleOrderClick = (product) => {
        setSelectedProduct(product);
        setShowOrderForm(true);
    };

    return (
        <div>
            <h1>Список продуктов</h1>
            <Link to="/">
                <Button>Вернуться на главную</Button>
            </Link>
            <ul>
                {products.map((product) => (
                    <li key={product.id}>
                        <h2>{product.name}</h2>
                        <p>{product.description}</p>
                        <p>Цена за день аренды: {product.rental_price_per_day} ₽</p>
                        <button onClick={() => handleOrderClick(product)}>Заказать</button>
                        {product.is_available ? (
                            <p style={{ color: 'green' }}>Доступно для аренды</p>
                        ) : (
                            <p style={{ color: 'red' }}>Недоступно (в аренде)</p>
                        )}
                    </li>
                ))}
            </ul>
            {showOrderForm && <OrderForm product={selectedProduct} />}
        </div>
    );    
};

export default ProductsPage;
